














import { Component, Vue, Prop } from 'vue-property-decorator'
import { AppModule } from '@/store/module'

@Component
export default class HelpBlock extends Vue {
  /** 文字颜色 */
  @Prop({
    type: String,
    default: 'dark'
  })
  theme: string

  list = [
    {
      title: 'PinMe QR code落單有什麼好處？',
      content: '支持靈活的餐單設置<br />支持100%自定義的菜品配置<br />支持複雜套餐、自由配搭的菜品<br />方便流暢的掃碼落單流程體驗',
    },
    {
      title: 'PinMe如何帮餐廳提升效率？',
      content: '提供全電子流程的「智慧餐廳」系統<br />菜單、價格、操作流程，一切皆可實時遠程配置<br />餐廳所有營運步驟都可監控<br />營運細節數據實時可查詢',
    },
    {
      title: 'PinMe如何幫助建立餐廳會員體系？',
      content: '餐廳可以輕鬆擁有自己的「會員系統」<br />簡易的會員信息管理（稱呼/生日/手機/郵箱）<br />餐廳會員價/ 會員專屬優惠券/ 會員生日禮<br />靈活的會員優惠設置會員數據分析',
    },
    {
      title: '餐廳開通會員系統，是否額外收費？',
      content: 'PinMe目前的餐飲POS系統中包含了會員系統<br />提供all in one的打包價格，沒有會員系統的額外收費<br />建議餐廳儘早加入，免費享受強大的會員系統',
    },
    {
      title: 'PinMe有哪些餐廳營銷方案？',
      content: '支持會員精準推送（短信）<br />支持電子印花，省去印花製作成本<br />靈活強大的優惠券（滿減券、折扣券、菜品兌換券）20多種營銷方案<br />雲餐廳網絡鏈接，一鍵嵌入社交媒體facebook、whatsapp群組、wechat群<br />大幅度提升外賣、到店自提的營業額',
    },
    {
      title: '是否支持服務員手持Pos？',
      content: 'PinMe提供可下載的手持Pos APP<br />可下載到手機上，登錄服務員帳號<br />即可在手機上操作點餐、取消<br />節省成本、簡單易用',
    },
    {
      title: '餐廳老闆有很高的報表統計需求，怎麼解決？',
      content: 'PinMe提供專業的餐廳報表統計模塊<br />餐廳日結單、餐廳運營報表<br />餐廳的分時段營運統計報表<br />訂單統計、菜品統計、原料統計<br />連鎖品牌的多門店報表匯總等等',
    },
    {
      title: 'PinMe Pos支持哪些支付方式？',
      content: '八達通 online & off-line支付<br />VISA / Master / Apple Pay<br />Alipay / Wechat pay/ 銀聯支付<br />現金支付（需配備錢箱）',
    }
  ]

  get isH5() {
    return AppModule.isH5
  }

  getList () {
    return this.list
  }
}
